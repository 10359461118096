<template>
  <div class="w-full contact">
    <div
      class="w-full p-4 mx-auto my-3 border rounded-lg shadow-lg md:p-8 p-none border-gray card sm:w-3/4 md:w-1/2"
    >
      <h1 class="mb-6 text-3xl uppercase font-b">Contact <span>Us</span></h1>
      <transition name="fade">
        <div
          class="p-3 mb-4 text-xl text-white bg-green-500 border border-green-500 rounded-md shadow-md opacity-50"
          v-if="showSuccess"
        >
          Your contact info has been received successfully.
        </div>
      </transition>
      <!-- <button @click="showSuccess = !showSuccess">Toggle</button> -->
      <div class="text-left">
        <form class="text-left login-form" @submit.prevent="submitForm">
          <label class="block">
            <span class="text-xs text-red-700" v-if="invalid.nameRequired"
              >Name field is required</span
            >
            <input
              type="text"
              placeholder="Name*"
              name="name"
              class="block w-full px-4 py-2 mb-2 border border-gray-300 form-input"
              v-model="form.name"
              data-required="true"
            />
          </label>
          <label class="block">
            <span
              class="block text-xs text-red-700"
              v-if="invalid.emailRequired"
              >Email field is required</span
            >
            <span class="block text-xs text-red-700" v-if="invalid.emailFormat"
              >Please enter a valid email address</span
            >
            <input
              type="text"
              placeholder="Email*"
              name="email"
              class="block w-full px-4 py-2 mb-2 border border-gray-300 form-input"
              v-model="form.email"
              data-required="true"
              data-email="true"
            />
          </label>
          <label class="block">
            <input
              type="text"
              placeholder="Phone"
              name="phone"
              class="block w-full px-4 py-2 mb-2 border border-gray-300 form-input"
              v-model="form.phone"
            />
          </label>
          <label class="block">
            <textarea
              placeholder="Address"
              name="address"
              class="block w-full px-4 py-2 mb-2 border border-gray-300 form-input"
              v-model="form.address"
            ></textarea>
          </label>
          <label class="block">
            <span class="text-xs text-red-700" v-if="invalid.messageRequired"
              >Message field is required</span
            >
            <textarea
              placeholder="Message*"
              name="message"
              v-model="form.message"
              class="block w-full px-4 py-2 mb-2 border border-gray-300 form-input"
              data-required="true"
            ></textarea>
          </label>
          <button
            class="px-6 py-2 mt-2 text-lg text-gray-200 rounded hover:opacity-75"
            type="submit"
          >
            Submit
          </button>
        </form>
        <the-spinner v-if="loadingState" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { ref, reactive, onBeforeMount, inject } from "vue";
import { logout, getUserName } from "../firebase.js";
import TheSpinner from "@/components/TheSpinner.vue";

export default {
  inject: ["global"],
  components: { TheSpinner },
  setup() {
    const invalid = reactive({
      nameRequired: false,
      emailRequired: false,
      emailFormat: false,
      messageRequired: false,
    });
    const form = reactive({
      name: "",
      email: "",
      phone: "",
      address: "",
      message: "",
    });
    // const appUrl = ref(
    //   "http://localhost:5001/trail-pictures/us-central1/app"
    // );
    const appUrl = ref(
      "https://us-central1-trail-pictures.cloudfunctions.net/app"
    );
    const loadingState = ref(false);
    const showSuccess = ref(false);
    const submitForm = () => {
      validate();
    };
    const validate = () => {
      const requiredFields = document.querySelectorAll(".form-input");
      let formInvalid = false;
      requiredFields.forEach((item) => {
        if (item.dataset.required === "true" && item.value.length === 0) {
          invalid[`${item.name}Required`] = true;
          formInvalid = true;
        } else {
          invalid[`${item.name}Required`] = false;
        }
        if (item.dataset.email === "true" && !validateEmail(item.value)) {
          invalid[`${item.name}Format`] = true;
          formInvalid = true;
        } else {
          invalid[`${item.name}Format`] = false;
        }
      });
      if (formInvalid === false) {
        addContact();
      }
    };
    const validateEmail = (email) => {
      const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    };
    const addContact = () => {
      loadingState.value = true;
      axios
        .post(`${appUrl.value}/api/add/contact`, form)
        .then((result) => {
          console.log("contact upload result:", result);
          loadingState.value = false;
          successPopup();
        })
        .catch((err) => {
          console.log("err:", err);
          loadingState.value = false;
        });
    };
    const successPopup = () => {
      showSuccess.value = true;
      setTimeout(() => {
        showSuccess.value = false;
        form.name = "";
        form.email = "";
        form.phone = "";
        form.address = "";
        form.message = "";
      }, 4000);
    };
    onBeforeMount(() => {
      // name.value = getUserName();
    });
    return {
      form,
      invalid,
      submitForm,
      loadingState,
      showSuccess,
    };
  },
};
</script>

<style lang="scss" scoped>
form {
  padding: 0;
}
.bullet-list {
  text-align: left;

  li {
    margin: 0 0 8px 0;
  }

  li:before {
    content: "✓ ";
  }
}
.hero {
  background-image: url(~@/assets/images/hero-img.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  height: 300px;
  width: 100%;
}
.fade-enter-from {
  opacity: 0;
}
.fade-enter-to {
  opacity: 0.5;
}
.fade-enter-active {
  transition: all 0.7s ease-in;
}
.fade-leave-from {
  opacity: 0.5;
}
.fade-leave-to {
  opacity: 0;
}
.fade-leave-active {
  transition: all 0.7s ease-out;
}
.login-form button {
  background-color: #265aa9;
}
.contact h1 {
  color: #265aa9;
}
</style>